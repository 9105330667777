@import '../../utils/variables';

$fixed-menu-height: 101px;
$mobile-menu-width: 300px;

.navbar {
	height: $fixed-menu-height;
	@media only screen and (min-width: 1400px) {
		padding-left: 5rem;
		padding-right: 5rem;
	}
	@media only screen and (min-width: 1600px) {
		padding-left: 10rem;
		padding-right: 10rem;
	}
	#navbar-menu .nav-item {
		@media only screen and (min-width: 1200px) and (max-width: 1300px) {
			padding-right: 1rem;
			padding-left: 1rem;
		}
		@media only screen and (min-width: 1301px) and (max-width: 1450px) {
			padding-right: 2rem;
			padding-left: 2rem;
		}
		@media only screen and (min-width: 1451px) and (max-width: 1599px) {
			padding-right: 2.5rem;
			padding-left: 2.5rem;
		}
		@media only screen and (min-width: 1600px) and (max-width: 1820px) {
			padding-right: 3rem;
			padding-left: 3rem;
		}
		@media only screen and (min-width: 1821px) {
			padding-right: 5rem;
			padding-left: 5rem;

		}
	}
}
#navbar-logo {
	padding: 1rem 0;
	margin-right: 5rem;

    img {
        max-width: calc(#{$mobile-menu-width} - 2rem);
    }
}

#navbar-menu {
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1.8rem;
	letter-spacing: 0.2em;
	color: $body-color;
}

// sm, md, lg, xl
@media only screen and (min-width: 576px) {
    // ...
}

// md, lg, xl
@media only screen and (min-width: 768px) {
    // ...
}

// lg, xl
@media only screen and (min-width: 992px) {
    // ...
}

// xl
@media only screen and (min-width: 1200px) {
    .navbar {
		height: $fixed-menu-height;
		border-bottom: 1px solid $primary;
		transition: all 0.2s ease-in-out;
    }
    #navbar-logo img {
        max-width: 150px;
    }
    #navbar-overlay {
        opacity: 0;
    }
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
    #navbar-menu {
        width: 100%;
        justify-content: space-evenly;
		.nav-item{
			&.active, &:hover {
				.nav-link {
					box-shadow: 0px 4px 1px -3px $primary;
					text-decoration: none;
				}
			}
			.nav-link {
				color: $secondary;
				padding-bottom: 1rem;
			}
		}
    }
}

// xs, sm, md, lg
@media only screen and (max-width: 1199.98px) {
    #navbar-logo {
        img {
            width: 20rem;
        }
    }
    .navbar {
        background: transparent!important;
        width: 0;
        left: 10px;
        position: fixed;
        top: -20px;
        z-index: $zindex-fixed;
    }
    #menu {
        position: fixed;
        top: 0;
        left: 0;
        // padding-top: $fixed-menu-height!important;
        padding-top: 0!important;
        height: 100vh!important;
        width: auto;
        width: $mobile-menu-width;
        overflow-y: auto;
        background: white;

        & > * {
            min-width: 100%;
        }
    }
    .transition-standard{
        transition: all 0.3s ease-in-out;
    }
    .navbar-toggler {
        outline: none!important;
        z-index: 1;
        background: $primary;
        padding: 0;
        height: 40px;
        width: 40px;
        box-sizing: content-box;
        line-height: 0;
        &:hover {
            opacity: 0.8;
        }
    }
    .navbar-toggler-icon {
        background-image: none!important;
        background: transparent;
        height: 2px;
        left:50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1.5rem;
        &::before {
            transform: rotate(45deg);
            top: 0;
        }

        &::after {
            transform: rotate(-45deg);
            bottom: 0;
        }
        &::after,
        &::before {
            background-color: white;
            content: "";
            display: block;
            height: 2px;
            position: absolute;
            width: 1.5rem;
            transform-origin: center;
        }
    }

    .navbar-toggler.collapsed .navbar-toggler-icon {
        &::before {
            transform: none;
            top: -7px;
        }
        &::after {
            transform: none;
            bottom: -7px;
        }
        background: white;
    }
    .navbar-toggler.collapsed {
        & ~ #menu {
            transform: translateX(-#{$mobile-menu-width});
        }
        & ~ #navbar-overlay {
            opacity: 0;
            visibility: hidden;
        }
    }
    #menu.collapse {
        &:not(.show) {
          display: block !important;
        }
    }
    .collapse {
        display: block;
    }
    #navbar-overlay {
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
        opacity: 1;
        visibility: visible;
        z-index: -1;
    }
    #navbar-menu {
        width: 100%;
        position: relative;
        .nav-item {
            transition: all 0.3s ease-in-out;
            .nav-link,
            .dropdown-item {
                padding: 1rem;
                width: 100%;
                margin-top: -1px;
                color:black;
            }
            & + .nav-item .nav-link {
                border-top: 1px solid transparent;
            }
            &.divider {
                display: none;
            }
            &.active {
                .nav-link {
                    box-shadow: 0px 4px 1px -3px $primary;
                    text-decoration: none;
                }
            }
            &.active.parent {
                background: rgba($secondary, .5);
            }
            &:hover,
            &:focus {
                
            }
            &.dropdown.show {
                transition: none;
                position: absolute;
                z-index: 1;
                top: 0;
                width: 100%;
                height: 100%;
                background: $primary;
                .nav-link {
                    border-top: 1px solid $primary;
                    margin-top: -1px;
                    background: rgba($secondary, .5);
                }
                .nav-item {
                    a {
                        padding-left: 2rem;
                    }
                }
                .dropdown-toggle::after {
                    transform: rotate(90deg);
                }
                .dropdown-menu {
                    height: auto;
                    background: $primary;
                }
            }
            .dropdown-toggle::after {
                float: right;
                margin-top: calc((1.5em - 0.3em)/2);
                transform: rotate(-90deg);
            }
        }
        .dropdown-menu {
            padding: 0;
            margin: 0;
            border: none;
            background: transparent;
            margin-top: -1px;
            height: 0;
            transition: all 0.3s ease-in-out;
            .dropdown-item {
                color: rgba(255, 255, 255, 0.5);
                &.active,
                &:hover,
                &:focus {
                    color: white;
                    background: transparent;
                }
            }
            .nav-item.active .dropdown-item {
                    color: white;
            }
        }
    }
    .google-translate {
        margin-top: calc(100% - 250px);
        & .dropdown-menu {
            right: 1rem;
            @media only screen and (max-width: 1200px) {
                right: 50%;
                transform: translateX(50%);
            }
        }
    }
}
